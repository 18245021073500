import { Typography } from '@mui/material';


export const AppContentOverlay = ({handleClick}: any) => {

  return (
    <div className="relative w-full h-full flex items-center justify-center">
      <div className="absolute inset-0 bg-[#262626] opacity-80 backdrop-filter backdrop-blur-lg"></div>
      {/* Overlay content */}
      <div tabIndex={0} role='button' className="relative z-10 p-4 m-4 bg-[#474747] rounded-lg shadow-lg" onClick={handleClick}>
        <Typography variant="h5" className="text-xl font-bold text-center  mb-4">
          This content can be viewed by premium users only.
        </Typography>
      </div>
    </div>
  );
};
