import { useNavigate } from 'react-router-dom';


import { APP_LOGOUT } from 'src/redux/slices/auth';
import { APP_PERSISTOR, useAppDispatch } from 'src/redux';
import { APP_PATHS, makePath } from 'src/routes/paths';
import { useLogoutMutation } from 'src/services/auth';
import { baseAPI } from 'src/services/base-api';



export const useLogout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [logOutUser] = useLogoutMutation()


  
  const handleLogout = () => {
    logOutUser();
    APP_PERSISTOR.purge();
    localStorage.removeItem('persist:ZENIN_TOJI_WORLD');
    dispatch(APP_LOGOUT());
    dispatch(baseAPI.util.resetApiState());
    navigate(makePath(APP_PATHS.INDEX, APP_PATHS.LOGIN), { state: APP_PATHS.INDEX });
  };

  // useEffect(() => {

  //   // // Call handleLogout when the component using this hook unmounts
  //   // return () => handleLogout();
  // }, [dispatch, navigate]);



  return handleLogout;
};
