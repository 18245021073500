// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
export const BASE_URL = 'https://app.memeworld.com.pk';
// export const BASE_URL = process.env.REACT_APP_BASE_URL;






// SETTINGS
// ##################################################################################################


export const cookiesExpires = 3;

export const cookiesKey = {
    themeMode: "themeMode",
    themeDirection: "themeDirection",
    themeColorPresets: "themeColorPresets",
};

export const defaultSettings = {
    themeMode: "light",
    themeDirection: "ltr",
    themeColorPresets: "default",
};
