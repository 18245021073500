import React from 'react';


import { Outlet } from 'react-router';
import AppTabsComponent from 'src/components/tabs';
import { APP_HOME_TABS } from 'src/constants';
import { useCurrentPathObj } from 'src/hooks/useCurrentPath';


// ##################################################################################################


export default function AppHomeLayout() {

  const currentPathObj = useCurrentPathObj(APP_HOME_TABS);



    return (
        <div className=" lg:px-4 lg:m-auto">
            <AppTabsComponent tabValue={currentPathObj?.id} tabs={APP_HOME_TABS} />

            <Outlet />
        </div>
    )


}
