// ----------------------------------------------------------------------

export default function Typography(theme: any) {
    return {
        MuiTypography: {
            styleOverrides: {
                paragraph: {
                    marginBottom: theme.spacing(2),
                },
                gutterBottom: {
                    marginBottom: theme.spacing(1),
                },

                '&.bodyGrey1': {
                    color: theme.palette.grey[800],
                },

            },
        },
    };
}
