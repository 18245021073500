import React from 'react'


// import { TransitionProps } from '@mui/material/transitions';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material'




const Transition = React.forwardRef(function Transition(
    props: any & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});



export const DialogueComponent = ({ open, title, content, customClasses, okText, handleClose, onOk, buttonOnTop, isLoading }: any) => {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            // maxWidth={'sm'}
            // closeAfterTransition
            aria-describedby="alert-dialog-slide-description"
        >
            {buttonOnTop && (
                <div className='flex items-center justify-between gap-5 px-6 py-4'>
                    <DialogTitle className={`${customClasses} p-0`} style={{ padding: 0 }}>
                        <span className='text-3xl text-semibold'>{title}</span>
                    </DialogTitle>


                    <Button variant='contained' className='max-w-[200px] h-[40px] text-lg font-semibold flex items-center gap-2' onClick={onOk}
                        sx={{ fontSize: '18px' }}
                        disabled={isLoading}
                    >
                        <span className='block pt-1'>
                            {isLoading && <CircularProgress color={'inherit'} size={20} />}
                        </span>
                        <p>{okText}</p>
                    </Button>


                </div>
            )}

            {!buttonOnTop && (
                <DialogTitle className={`${customClasses}`}>
                    <span className='text-3xl text-semibold'>{title}</span>
                </DialogTitle>
            )}

            <DialogContent>
                {content}
            </DialogContent>

            {!buttonOnTop && (
                <DialogActions>
                    <Button variant='contained' className='w-[200px] sm:w-[250px] md:w-[290px] h-[56px] text-lg font-semibold' onClick={onOk}
                        sx={{ fontSize: '18px' }}
                    >{okText}</Button>
                </DialogActions>
            )}

        </Dialog>
    )
}




