import { useEffect, useRef, useState } from 'react';


import { useLocation } from 'react-router-dom';
import { I_APP_HOME_TABS } from 'src/constants';


// ##################################################################################################


export const useCurrentPathObj = (tabs: I_APP_HOME_TABS[]) => {


    const location = useLocation();
    const previousPathnameRef = useRef<string>();

    const currentHomePath = location.pathname.split('/')[2];

    const [currentPathObj, setCurrentPathObj] = useState<I_APP_HOME_TABS | undefined>(undefined);



    useEffect(() => {
        const getCurrentPathObj = () => {
            return tabs.find(
                (singleTab) => singleTab.name.toLowerCase() === currentHomePath.toLowerCase()
            );
        };

        
        // Check if the previous pathname is different from the current pathname
        if (previousPathnameRef.current !== location.pathname) {

            setCurrentPathObj(getCurrentPathObj());
            previousPathnameRef.current = location.pathname; // Update the previous pathname
        }

    }, [location.pathname, tabs, currentHomePath]);


    return currentPathObj;
};