// =========== IMG ===========
import giftImg from "./img/login/gift.png";
import coinsImg from "./img/login/coins.png";
import socialImg from "./img/login/social.png";
import dummyMemeImg from "./img/dummy-meme.png";
import dummyMemeImg2 from "./img/dummy-meme-2.png";
import schoolBannerImg from "./img/home/school-banner.png";
import spinWheelImg from "./img/home/spin-wheel-banner.png";
import memeImg192 from "./img/meme-192.png";


// =========== SVG ===========
import homeSvg from "./svg/home.svg";
import userSvg from "./svg/user.svg";
import likeSvg from "./svg/like.svg";
import editSvg from "./svg/edit.svg";
import listSvg from "./svg/list.svg";
import lockSvg from "./svg/lock.svg";
import boxesSvg from "./svg/boxes.svg";
import powerSvg from "./svg/power.svg";
import crossSvg from "./svg/cross.svg";
import shareSvg from "./svg/share.svg";
import greySvg from "./svg/grey-pin.svg";
import getAppSvg from "./svg/get-app.svg";
import commentSvg from "./svg/comment.svg";
import favPostSvg from "./svg/fav-post.svg";
import settingsSvg from "./svg/settings.svg";
import userIconSvg from "./svg/user-icon.svg";
import menWithFlagSvg from "./svg/men-flag.svg";
import arrowRightSvg from "./svg/arrow-right.svg";
import logoSmSvg from "./svg/meme-world-logo.svg";
import appStoreSvg from "./svg/app-store-icon.svg";
import mobileGroupSvg from "./svg/mobile-group.svg";
import colorFulPinSvg from "./svg/colorful-pin.svg";
import playStoreSvg from "./svg/play-store-icon.svg";



// ##################################################################################################

export const APP_ASSETS = {
    SVG: {
        homeSvg,
        greySvg,
        listSvg,
        likeSvg,
        lockSvg,
        userSvg,
        editSvg,
        shareSvg,
        boxesSvg,
        powerSvg,
        crossSvg,
        logoSmSvg,
        getAppSvg,
        commentSvg,
        favPostSvg,
        settingsSvg,
        userIconSvg,
        appStoreSvg,
        playStoreSvg,
        arrowRightSvg,
        colorFulPinSvg,
        menWithFlagSvg,
        mobileGroupSvg
    },
    IMG: {
        giftImg,
        coinsImg,
        socialImg,
        memeImg192,
        spinWheelImg,
        dummyMemeImg,
        dummyMemeImg2,
        schoolBannerImg,
    }
}