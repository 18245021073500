// import React, { useState, useRef, useEffect, KeyboardEvent } from 'react';
// import { TextField, Chip, IconButton } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';



// interface AppInputHashtagsProps {
//   value: string[];
//   onChange: (tags: string[]) => void;
// }

// export const AppInputHashtags: React.FC<AppInputHashtagsProps> = ({ value, onChange }) => {

//   const [inputValue, setInputValue] = useState('');
//   const [tags, setTags] = useState<string[]>(value);

//   const inputRef = useRef<HTMLInputElement>(null);




//   useEffect(() => {
//     setTags(value);
//   }, [value]);



//   const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setInputValue(event.target.value);
//   };


//   const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
//     if (event.key === 'Enter' && inputValue.trim() !== '') {
//       event.preventDefault();
//       addTag(inputValue.trim());
//     }
//   };



//   const addTag = (tag: string) => {
//     const trimmedTag = tag.trim();
//     if (trimmedTag && !tags.includes(trimmedTag)) {
//       const newTags = [...tags, trimmedTag];
//       setTags(newTags);
//       onChange(newTags);
//       setInputValue('');
//     }
//   };



//   const removeTag = (tag: string) => {
//     const newTags = tags.filter((t) => t !== tag);
//     setTags(newTags);
//     onChange(newTags);
//   };



//   const handleAddButtonClick = () => {
//     if (inputValue.trim() !== '') {
//       addTag(inputValue.trim());
//     }
//   };



//   return (
//     <div>
//       <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', marginBottom: '8px' }}>
//         {tags.map((tag) => (
//           <Chip
//             key={tag}
//             label={tag}
//             onDelete={() => removeTag(tag)}
//             color="primary"
//             variant="outlined"
//           />
//         ))}
//       </div>
//       <div style={{ display: 'flex', alignItems: 'center' }}>
//         <TextField
//           inputRef={inputRef}
//           fullWidth
//           label="Add Tag"
//           variant="outlined"
//           value={inputValue}
//           onChange={handleInputChange}
//           onKeyDown={handleInputKeyDown}
//         />
//         <IconButton onClick={handleAddButtonClick} color="primary" aria-label="Add Tag">
//           <AddIcon />
//         </IconButton>
//       </div>
//     </div>
//   );
// };


import React, { useState, useRef, useEffect, KeyboardEvent } from 'react';
import { TextField, Chip, IconButton, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface TagsInputProps {
  value: string[]; // Array of current tags
  onChange: (tags: string[]) => void; // Function to handle tag changes
}

export const AppInputHashtags: React.FC<TagsInputProps> = ({ value, onChange }) => {
  const [inputValue, setInputValue] = useState('');
  const [tags, setTags] = useState<string[]>(value);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTags(value);
  }, [value]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      event.preventDefault();
      addTag(inputValue.trim());
    }
  };

  const addTag = (tag: string) => {
    const trimmedTag = tag.trim();
    if (trimmedTag && !tags.includes(trimmedTag)) {
      const newTags = [...tags, trimmedTag];
      setTags(newTags);
      onChange(newTags);
      setInputValue('');
    }
  };

  const removeTag = (tag: string) => {
    const newTags = tags.filter((t) => t !== tag);
    setTags(newTags);
    onChange(newTags);
  };

  const handleAddButtonClick = () => {
    if (inputValue.trim() !== '') {
      addTag(inputValue.trim());
    }
  };

  return (
    <Box width={'100%'} display="flex" flexWrap="wrap" alignItems="center" gap={1}>
      {tags.map((tag) => (
        <Chip
          key={tag}
          label={tag}
          onDelete={() => removeTag(tag)}
          color="primary"
          variant="outlined"
          size="small"
          sx={{ marginBottom: 1 }}
        />
      ))}
      <TextField
        inputRef={inputRef}
        fullWidth
        label="Add Tag"
        variant="outlined"
        value={inputValue}
        placeholder='Add at least one tag'
        className='w-full'
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        InputProps={{
          endAdornment: (
            <IconButton onClick={handleAddButtonClick} color="primary">
              <AddIcon />
            </IconButton>
          ),
        }}
      />
    </Box>
  );
};