// ----------------------------------------------------------------------

export default function Dialog(theme: any) {
    return {
      MuiDialog: {
        styleOverrides: {
          paper: {
            boxShadow: theme.customShadows.dialog,
            backgroundColor: theme.palette.grey[800],
            background: theme.palette.grey[800],

            width:'100%',
            "&.MuiPaper-rounded": {
              borderRadius: Number(theme.shape.borderRadius) * 2,
            },
            "&.MuiDialog-paperFullScreen": {
              borderRadius: 0,
            },
            "&.MuiDialog-paper .MuiDialogActions-root": {
              padding: theme.spacing(3),
            },
            "@media (max-width: 600px)": {
              margin: theme.spacing(2),
            },
            "@media (max-width: 663.95px)": {
              "&.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody": {
                maxWidth: "100%",
              },
            },
          },
          paperFullWidth: {
            width: "100%",
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: theme.spacing(3, 3, 0),
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            borderTop: 0,
            borderBottom: 0,
            padding: theme.spacing(3),
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            "& > :not(:first-of-type)": {
              marginLeft: theme.spacing(1.5),
            },
            margin:'auto'
          },
        },
      },
    };
  }
  