import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";


import { AppBar, Box, Button, CssBaseline, Divider, Drawer, FormControlLabel, IconButton, List, ListItem, ListItemButton, ListItemText, ListSubheader, TextField, Toolbar, Typography, useTheme } from "@mui/material";


import ListItemIcon from '@mui/material/ListItemIcon';


import { v4 as uuidv4 } from 'uuid';


import { SIDEBAR_INTERESTS, SIDEBAR_ROUTES } from "./sidebar-routes";
import { I_SIDEBAR_INTERESTS, I_SIDEBAR_INTERESTS_CHILDREN, I_SIDEBAR_ROUTES } from "src/types/sidebar";


import MenuIcon from '@mui/icons-material/Menu';

import { APP_ASSETS } from "src/assets";
import { debouncedSearch, isAllowedToView, isNullOrEmpty } from "src/utils";
import { APP_API_METHODS, DRAWER_WIDTH } from "src/constants";
import { enqueueSnackbar } from "notistack";
import { useCreatePostMutation, useWritePostMutation } from "src/services/post";
import useApiResponse from "src/hooks/useApiResponse";
import { APPInputComponent, AppDropdownWrapper, AppInputHashtags, AppMultipleSelectionComponent, AppSwitchComponent, DialogueComponent } from "src/components";


import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import { RootState, useAppSelector } from "src/redux";
import { useGetInterestsQuery } from "src/services/home";
import { useLogout } from "src/hooks";
import { APP_API_END_POINTS } from "src/services/endpoints";
import { BASE_URL } from "src/config";
import { BASIC_AUTH } from "src/services/base-api";


// ##################################################################################################



const MinHeightTextarea = ({ placeholder, name, value, onChange }: any) => {
    const blue = {
        100: '#DAECFF',
        200: '#b6daff',
        400: '#3399FF',
        500: '#007FFF',
        600: '#0072E5',
        900: '#003A75',
    };

    const grey = {
        50: '#F3F6F9',
        100: '#E5EAF2',
        200: '#DAE2ED',
        300: '#C7D0DD',
        400: '#B0B8C4',
        500: '#9DA8B7',
        600: '#6B7A90',
        700: '#434D5B',
        800: '#303740',
        900: '#1C2025',
    };

    const Textarea = styled(BaseTextareaAutosize)(
        ({ theme }) => `
      box-sizing: border-box;
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      padding: 8px 12px;
      border-radius: 8px;
      color:#fff;
      background: transparent;
      border: 1px solid #fff;
      box-shadow: 0px 2px 2px #000;
  
      &:hover {
        border-color: #fff;
      }
  
      &:focus {
        border-color: #fff;
        box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : '#fff'};
      }
  
      // firefox
      &:focus-visible {
        outline: 0;
      }
    `,
    );

    return (
        <Textarea aria-label="postDesc" minRows={3} name={name} value={value} onChange={onChange} maxRows={4} placeholder={placeholder} />
    );
}





const CREATE_POST_DROPDOWN = [
    {
        value: 'uploadPost',
        name: 'Upload Post'
    },
    {
        value: 'writePost',
        name: 'Write Post'
    },
]






export default function DashboardLayout({ Outlet }: { Outlet: React.ReactNode | null }) {

    const APP_THEME = useTheme();

    const location = useLocation();

    const { user: userInfo } = useAppSelector((state: RootState) => state.auth.auth)


    // const { data: interestsData, isLoading: interestsDataLoading, refetch } = useGetInterestsQuery({ refetchOnMountOrArgChange: 1, count: 5, forceRefetch: true });



    const [mobileOpen, setMobileOpen] = useState(false);
    const [interestsData, setInterestsData] = useState<any>();
    const [isMobileScreen, setIsMobileScreen] = useState(false);
    const [openCreatePostType, setOpenCreatePostType] = useState('');
    const [writePostDesc, setWritePostDesc] = useState('');
    const [isShareAnonymously, setIsShareAnonymously] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [tags, setTags] = useState<string[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<any>([]);

    const [isCreatingPostLoading, setIsCreatingPostLoading] = useState(false);
    const [selectedRoute, setSelectedRoute] = useState('');

    // const availableOptions = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];



    // const handleLogout = useLogout();


    const [formData, setFormData] = useState({
        postDesc: '',
        postText: '',
        postTags: '',
        postCategory: '',
        postImg: ''
    });



    const [createPost] = useCreatePostMutation()
    const [writePost] = useWritePostMutation()



    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };


    const handleTagsChange = (newTags: string[]) => {
        setTags(newTags);
    };



    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            setSelectedFile(file);
        }
    };


    const handleInputChange = (e: any) => {

        const { name, value } = e.target;


        setFormData({
            ...formData,
            [name]: value
        });
    };


    const renderFilePreview = () => {
        if (!selectedFile) return null;

        // Check file type to determine preview
        if (selectedFile.type.startsWith('image/')) {
            return <img src={URL.createObjectURL(selectedFile)} alt="Preview" style={{ maxWidth: '100%' }} />;
        } else if (selectedFile.type.startsWith('video/')) {
            return (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video controls style={{ maxWidth: '100%' }}>
                    <source src={URL.createObjectURL(selectedFile)} type={selectedFile.type} />
                    Your browser does not support the video tag.
                </video>
            );
        } else {
            return <Typography variant="body1">{`File: ${selectedFile.name}`}</Typography>;
        }
    };



    const handleSelectionChange = (newValue: any) => {

        console.log('newSelection', newValue);


        setSelectedOptions(newValue);
    };


    const handleCreatePost = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();




        const formDataa = new FormData();


        if (openCreatePostType === 'uploadPost') {

            const indexesCategory: any = { categories: {} };
            const indexesTags: any = { hashtags: {} };

            // Generate indexed categories from selectedOptions
            selectedOptions.forEach((option: any, index: any) => {
                indexesCategory.categories[`category_id[${index}]`] = option;
            });


            tags.forEach((tag, index) => {
                indexesTags.hashtags[`hashtags[${index}]`] = tag;
            });

            const body = {
                msisdn: userInfo?.result?.msisdn,
                post_type: 1,
                anon: isShareAnonymously,
                mediaContent: selectedFile,
                ...indexesCategory.categories,
                ...indexesTags.hashtags
            }



            formDataa.append('msisdn', userInfo?.result?.msisdn);
            formDataa.append('post_type', '1');
            formDataa.append('anon', isShareAnonymously ? '1' : '0');
            formDataa.append('description', formData?.postDesc); // Add other form data fields




            // Append selectedFile as mediaContent
            if (selectedFile) {
                formDataa.append('mediaContent', selectedFile);
            }

            // Append indexed categories
            selectedOptions.forEach((option: any, index: any) => {
                formDataa.append(`category_id[${index}]`, '1');
            });


            tags.forEach((tag, index) => {
                formDataa.append(`hashtags[${index}]`, tag);
            });



        }



        if (openCreatePostType === 'writePost') {

            // formDataa.append('msisdn', userInfo?.result?.msisdn);
            formDataa.append('text', formData?.postText); // Add other form data fields
            formDataa.append('post_type', '4');
            formDataa.append('description', formData?.postDesc); // Add other form data fields
            formDataa.append('anon', isShareAnonymously ? '1' : '0');

            formDataa.append(`colors[${0}]`, 'ff7a00');
            formDataa.append(`colors[${1}]`, 'ffc300');
            formDataa.append(`colors[${2}]`, '4ea1d3');
            formDataa.append(`colors[${3}]`, 'fb794e');
            formDataa.append(`colors[${4}]`, '1a659e');

            // Append indexed categories
            selectedOptions.forEach((option: any, index: any) => {
                formDataa.append(`category_id[${index}]`, '1');
            });


            tags.forEach((tag, index) => {
                formDataa.append(`hashtags[${index}]`, tag);
            });



        }





        try {

            setIsCreatingPostLoading(true);

            const apiFunctionToHit = openCreatePostType === 'writePost' ? writePost : createPost

            const res = await apiFunctionToHit(formDataa).unwrap();

            // if (!res?.success) {
            //     const errorMessage = useApiResponse(res?.message);
            //     // setVerifyOTPError(errorMessage)
            //     throw new Error(errorMessage)
            // }


            console.log('res in create/write post =======≥≥≥≥≥', res);


            if (res?.message === 'Data Uploaded, Meme Shared Successfully') {
                enqueueSnackbar(`Post uploaded successfully!`, { variant: "success" });
                setOpenCreatePostType('');
                setFormData({
                    postDesc: '',
                    postText: '',
                    postTags: '',
                    postCategory: '',
                    postImg: ''
                });
                return
            }

            if (res?.message === 'Data Uploaded, Meme Shared Successfully') {
                enqueueSnackbar(`Post uploaded successfully!`, { variant: "success" });
                setOpenCreatePostType('');
                setFormData({
                    postDesc: '',
                    postText: '',
                    postTags: '',
                    postCategory: '',
                    postImg: ''
                });
                return
            }

            if (res?.message === 'Post Created Successfully') {
                enqueueSnackbar(`Post Created successfully!`, { variant: "success" });
                setOpenCreatePostType('');
                setFormData({
                    postDesc: '',
                    postText: '',
                    postTags: '',
                    postCategory: '',
                    postImg: ''
                });
                return
            }





        } catch (error: any) {

            enqueueSnackbar(`${error}`, {
                variant: "error",
                autoHideDuration: 6000,
            });
        }

        finally {
            setIsCreatingPostLoading(false);
        }

    }


    const handleWritePostClicked = (data: any) => {
        console.log('data -======>>>ƒ', data);
        setOpenCreatePostType(data)
    }




    useEffect(() => {
        // Extract the key from the current pathname
        const currentKey = location.pathname.split('/')[1];
        setSelectedRoute(currentKey);
    }, [location.pathname]);




    useEffect(() => {
        const checkScreenWidth = () => {
            setIsMobileScreen(window.innerWidth < 1200);
        };

        // Call the function once on component mount
        checkScreenWidth();

        // Add event listener to window resize event
        window.addEventListener("resize", checkScreenWidth);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", checkScreenWidth);
        };
    }, []);






    useEffect(() => {

        const getRefetched = async () => {
            try {

                const resFirst = await fetch(`${BASE_URL}/${APP_API_END_POINTS.getInterests}`, {
                    method: APP_API_METHODS.get,
                    headers: {
                        // "Content-type": "application/json",
                        "Authorization": `Basic ${BASIC_AUTH}`,
                        'token': `${userInfo?.token}`,
                        'Cache-Control': 'no-store, max-age=0'
                    },
                });

                if (!resFirst.ok) {
                    throw new Error('Something went wrong. Please try again.');
                }


                const resFirstResonse = await resFirst.json();

                console.log('resFirstResonse', resFirstResonse);



                if (resFirstResonse?.message === 'Invalid token, Please login again') {
                    enqueueSnackbar(`${resFirstResonse?.message}`, { variant: "error" });
                    // handleLogout();
                    return
                }

                setInterestsData(resFirstResonse)



            } catch (error: any) {
                enqueueSnackbar(`${error}`, { variant: "error" });


            }
        }


        if (interestsData?.message === 'Token Not Found') {
            getRefetched()
        }



        getRefetched()

    }, [])



    console.log('writePostDescvwritePostDescwritePostDescwritePostDescwritePostDesc ', writePostDesc);



    return (
        <div className="w-full">
            <Box sx={{ display: 'flex' }}>

                <CssBaseline />

                <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Toolbar>
                        <div className=" w-full flex items-center justify-between">
                            <Link to="/home/trending">
                                <Typography variant="h6" noWrap component="div">
                                    <img src={APP_ASSETS.SVG.logoSmSvg} alt='logo' />
                                </Typography>
                            </Link>

                            {/* <div className="flex items-center gap-4">
                                <Typography variant="h6" noWrap component="div" sx={{ display: { xs: 'none', sm: 'block' } }}>
                                    SEARCH
                                </Typography>
                            </div> */}


                            {/* <Button variant="contained"
                                onClick={() => setIsOpenCreatePost(true)}
                                disabled={isCreatingPostLoading}
                            >

                                <Typography variant="h6" noWrap component="div" sx={{ display: { sm: 'none' } }}>
                                    Create +
                                </Typography>
                                <Typography variant="h6" noWrap component="div" sx={{ display: { xs: 'none', sm: 'block' } }}>
                                    CREATE POST
                                </Typography>

                            </Button> */}
                            <AppDropdownWrapper dropdownName={'CREATE POST'} dropdownArray={CREATE_POST_DROPDOWN} handleDropdownItemClicked={(data: any) => handleWritePostClicked(data)} />
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ ml: 2, display: { lg: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>

                <Drawer
                    variant={`${isMobileScreen ? 'temporary' : 'permanent'}`}
                    open={mobileOpen}
                    sx={{
                        width: DRAWER_WIDTH,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {
                            width: DRAWER_WIDTH,
                            border: 'none',
                            // marginLeft: '80px',
                            background: 'none',
                            boxSizing: 'border-box',

                        },
                        background: 'unset'
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >

                    <Toolbar />

                    <Box className="overflow-auto h-full my-4 rounded-xl" sx={{ background: APP_THEME.palette.grey[800] }}>

                        <List>
                            {SIDEBAR_ROUTES.map((sidebarItem: I_SIDEBAR_ROUTES) => (
                                <Link key={uuidv4()} to={`/${sidebarItem?.key}`} onClick={() => setMobileOpen(false)}>
                                    <ListItem disablePadding>
                                        <ListItemButton sx={{ padding: "0.8rem 1rem" }} selected={selectedRoute === sidebarItem.key}>
                                            <ListItemIcon>
                                                <img src={sidebarItem?.icon} alt={`${sidebarItem?.label}`} loading="eager" width={16} height={16} />
                                            </ListItemIcon>

                                            <ListItemText primary={sidebarItem?.label} className="capitalize font-semibold leading-6" sx={{
                                                "& .MuiTypography-root": {
                                                    fontWeight: 600
                                                }
                                            }} />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>
                            ))}
                        </List>

                        <Divider sx={{ m: '1.5rem' }} />

                        <List
                            className="w-full relative max-h-full overflow-auto"
                            sx={{
                                '& ul': { padding: 0 },
                                maxWidth: DRAWER_WIDTH
                            }}
                            subheader={<li />}
                        >
                            {interestsData?.user_data?.length && interestsData?.user_data.map((singleInterest: any) => {


                                // (singleInterest?.ispremium === userInfo?.result?.isPremium)




                                // const isAllowdedToView = (singleInterest?.ispremium === !!userInfo?.isPremium);



                                return (
                                    isAllowedToView(userInfo, singleInterest) && (
                                        <Link to={`/categories?id=${singleInterest?.categoryId}`} key={uuidv4()} className={`section-${singleInterest?.categoryName}`}>
                                            <ul>
                                                {/* <ListSubheader className="capitalize">{singleInterest?.categoryName}</ListSubheader> */}
                                                {/* {!isNullOrEmpty(singleInterest?.interestData) && (singleInterest?.interestData ?? []).map((subInterest: any) => ( */}
                                                <ListItem className="flex items-center justify-between gap-4 cursor-pointer w-full">

                                                    <div className="flex items-center gap-4 w-full">
                                                        {/* <img
                                                    alt='pin'
                                                    loading="eager"
                                                    // width={24}
                                                    // height={24}
                                                    style={{width:'100%', height:'100%'}}
                                                    src={
                                                        singleInterest?.categoryIcon
                                                    }
                                                /> */}
                                                        <ListItemText primary={`${singleInterest.categoryName}`} className="capitalize leading-6" />

                                                    </div>

                                                    {/* <img
                                                        alt='pin'
                                                        loading="eager"
                                                        width={24}
                                                        height={24}
                                                        src={
                                                            // subInterest.isInterestedIn ?
                                                            // APP_ASSETS.SVG.colorFulPinSvg 
                                                            APP_ASSETS.SVG.greySvg
                                                        }
                                                    /> */}

                                                </ListItem>
                                                {/* ))} */}
                                            </ul>
                                        </Link>
                                    )
                                )
                            })}
                            {!userInfo?.isPremium && (
                                <Typography variant="body1" color="text.primary" className='px-4 pt-6 text-center'>
                                    Want to <span className="text-[#E2B248] font-semibold">unlock</span> more categories? Subscribe now and use <span className="text-[#E2B248] font-semibold">20+</span> more categories!
                                </Typography>
                            )}
                        </List>

                    </Box>

                </Drawer>


                <Box component="main" sx={{ flexGrow: 1, p: { xs: '0', lg: '1.5rem 3rem 0rem 6rem' } }}>

                    <Toolbar />


                    <div className="flex gap-4 justify-between">

                        <div className="overflow-auto w-full max-w-[1020px] posts-outlet">
                            {Outlet}
                        </div>


                        {/* <div className="banner-section min-w-[281px] hidden md:block"> */}
                        {/* #TODO: Removed */}
                        {/* <img src={APP_ASSETS.IMG.spinWheelImg} alt='banner' />
                            <img src={APP_ASSETS.IMG.schoolBannerImg} alt='banner' className="my-4" /> */}
                        {/* </div> */}

                    </div>



                </Box>


                <DialogueComponent
                    open={(openCreatePostType === 'uploadPost')}
                    title="Create Post"
                    customClasses=""
                    isLoading={isCreatingPostLoading}
                    content={<div className="">
                        <div className="flex items-center flex-col gap-5">

                            <APPInputComponent
                                type='textarea'
                                name='postDesc'
                                label='Desc Your Post'
                                placeholder='Enter description ...'
                                value={formData.postDesc}
                                onChange={handleInputChange}
                            />


                            <div className="w-full">
                                <AppInputHashtags value={tags} onChange={handleTagsChange} />
                            </div>


                            <div className="w-full">
                                <AppMultipleSelectionComponent
                                    options={interestsData}
                                    value={selectedOptions}
                                    userInfo={userInfo}
                                    onChange={handleSelectionChange}
                                />
                            </div>

                            <div className="w-full">
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    accept="image/*, video/*"
                                    id="file-upload-input"
                                />
                                {!selectedFile ? (
                                    <label htmlFor="file-upload-input">
                                        <Button component="span" variant="contained" color="primary">
                                            Upload your file
                                        </Button>
                                    </label>
                                ) : (
                                    <div>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            value={selectedFile ? selectedFile.name : ''}
                                            disabled
                                            placeholder="Choose file..."
                                            onClick={(e) => e.preventDefault()}
                                        />
                                        {renderFilePreview()}
                                    </div>
                                )}
                            </div>

                            <div className="flex items-center justify-between px-[1rem] py-[1.2rem w-full">
                                <h3 className="text-base">Share Anonymously</h3>
                                {/* <img src={APP_ASSETS.SVG.arrowRightSvg} alt='arrow-right' className="cursor-pointer" /> */}
                                <FormControlLabel
                                    control={<AppSwitchComponent sx={{ m: 1 }} onChange={() => setIsShareAnonymously(!isShareAnonymously)}
                                    />}
                                    label=""
                                />

                            </div>


                        </div>
                    </div>}
                    okText="Post"
                    buttonOnTop
                    handleClose={() => {
                        setFormData({
                            postDesc: '',
                            postText: '',
                            postTags: '',
                            postCategory: '',
                            postImg: ''
                        });
                        // setEditProfile({ isOpen: false, data: {} })
                        setOpenCreatePostType('');
                    }}
                    onOk={handleCreatePost}
                />

                <DialogueComponent
                    open={(openCreatePostType === 'writePost')}
                    title="Write Post"
                    customClasses=""
                    isLoading={isCreatingPostLoading}
                    content={<div className="">
                        <div className="flex items-center flex-col gap-5 w-full">



                            <APPInputComponent
                                type='textarea'
                                name='postDesc'
                                label='Desc Your Post'
                                placeholder='Enter description ...'
                                value={formData.postDesc}
                                onChange={handleInputChange}
                            />

                            <div className="w-full">
                                <AppInputHashtags value={tags} onChange={handleTagsChange} />
                            </div>


                            <div className="w-full">
                                <AppMultipleSelectionComponent
                                    options={interestsData}
                                    value={selectedOptions}
                                    userInfo={userInfo}
                                    onChange={handleSelectionChange}
                                />
                            </div>



                            <textarea
                                rows={5}
                                name='postText'
                                placeholder={'Desc Your Post'}
                                value={formData.postText}
                                onChange={handleInputChange}
                                className="rounded-2xl w-full h-full outline-none border border-white resize-none overflow-y-auto bg-transparent px-3 py-2"
                            />




                            <div className="flex items-center justify-between px-[1rem] py-[1.2rem w-full">
                                <h3 className="text-base">Share Anonymously</h3>
                                {/* <img src={APP_ASSETS.SVG.arrowRightSvg} alt='arrow-right' className="cursor-pointer" /> */}
                                <FormControlLabel
                                    control={<AppSwitchComponent sx={{ m: 1 }} onChange={() => setIsShareAnonymously(!isShareAnonymously)}
                                    />}
                                    label=""
                                />

                            </div>



                        </div>
                    </div>}
                    okText="Post"
                    buttonOnTop
                    handleClose={() => {
                        setFormData({
                            postDesc: '',
                            postText: '',
                            postTags: '',
                            postCategory: '',
                            postImg: ''
                        });
                        // setEditProfile({ isOpen: false, data: {} })
                        setWritePostDesc('');
                        setOpenCreatePostType('');
                    }}
                    onOk={handleCreatePost}
                />

                {/* isOpenCreatePost */}

            </Box>
        </div>
    )
}
