import { I_SIDEBAR_INTERESTS, I_SIDEBAR_ROUTES } from "src/types/sidebar";

import { APP_ASSETS } from "src/assets";

// ##################################################################################################

export const SIDEBAR_ROUTES: I_SIDEBAR_ROUTES[] = [
  {
    key: "home",
    icon: APP_ASSETS.SVG.homeSvg,
    label: "Home",
  },
  {
    key: 'get-app',
    icon: APP_ASSETS.SVG.getAppSvg,
    label: 'get app',
  },
  // {
  //   key: 'rewards',
  //   icon: APP_ASSETS.SVG.homeSvg,
  //   label: 'rewards',
  // },

  {
    key: 'settings',
    icon: APP_ASSETS.SVG.settingsSvg,
    label: 'settings',
  },
  {
    key: "rewards",
    icon: APP_ASSETS.SVG.homeSvg,
    label: "rewards",
  },

  {
    key: "subscription",
    icon: APP_ASSETS.SVG.homeSvg,
    label: "subscription",
  },

];

// ##################################################################################################

export const SIDEBAR_INTERESTS: I_SIDEBAR_INTERESTS[] = [
  {
    interestLabel: "interest",
    interestData: [
      {
        key: "current-affairs",
        icon: "icon",
        label: "current affairs",
        isInterestedIn: true,
      },
      {
        key: "movies-series",
        icon: "icon",
        label: "movies/tv shows",
        isInterestedIn: false,
      },
      {
        key: "sports",
        icon: "icon",
        label: "Sports",
        isInterestedIn: true,
      },
      {
        key: "gaming",
        icon: "icon",
        label: "gaming",
        isInterestedIn: true,
      },
      {
        key: "family",
        icon: "icon",
        label: "family",
        isInterestedIn: false,
      },
      {
        key: "comics-lifestyle",
        icon: "icon",
        label: "comics lifestyle",
        isInterestedIn: true,
      },
      {
        key: "desi",
        icon: "icon",
        label: "desi",
        isInterestedIn: true,
      },
      {
        key: "vehicles",
        icon: "icon",
        label: "vehicles",
        isInterestedIn: true,
      },
      {
        key: "science-tech",
        icon: "icon",
        label: "science & tech",
        isInterestedIn: false,
      },
      {
        key: "food",
        icon: "icon",
        label: "food",
        isInterestedIn: false,
      },
      {
        key: "superhero",
        icon: "icon",
        label: "superhero",
        isInterestedIn: false,
      },
      {
        key: "crypto",
        icon: "icon",
        label: "crypto",
        isInterestedIn: false,
      },
      {
        key: "animals",
        icon: "icon",
        label: "animals",
        isInterestedIn: false,
      },
    ],
  },

  {
    interestLabel: "missing interests?",
    interestData: [],
  },
];
