import Link from "./link";
import Tabs from "./tabs";
import List from "./list";
import Card from "./card";
import Alert from "./alert";
import Radio from "./radio";
import Input from "./input";
import Dialog from "./dialog";
import Button from "./button";
import Progress from "./progress";
import Skeleton from "./skeleton";
import Typography from "./typography";
import Breadcrumbs from "./breadcrumbs";
import CssBaseline from "./css-baseline";
import Autocomplete from "./autocomplete";




export default function ComponentsOverrides(theme: any) {
    return Object.assign(
        Link(),
        List(theme),
        Tabs(theme),
        Card(theme),
        Alert(theme),
        Input(theme),
        Radio(theme),
        Dialog(theme),
        Button(theme),
        CssBaseline(),
        Skeleton(theme),
        Progress(theme),
        Typography(theme),
        Breadcrumbs(theme),
        Autocomplete(theme),
    );
}