import React from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';
import { isAllowedToView } from 'src/utils';
import { v4 as uuidv4 } from 'uuid';

interface MultipleSelectionProps {
  userInfo?: any;
  options?: any; // Array of available options
  value?: { label: string; value: string }[]; // Selected options
  onChange?: (newValue: { label: string; value: string }[]) => void; // Function to handle changes to selected options
}

export const AppMultipleSelectionComponent: React.FC<MultipleSelectionProps> = ({
  options,
  userInfo,
  value,
  onChange,
}) => {
  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  const transformedCategoriesOptions =
    options?.user_data?.filter((cat: any) => isAllowedToView(userInfo, cat))?.map((cat: any) => ({
      label: cat.categoryName,
      value: cat.categoryId,
    })) ?? [];

  return (
    <Autocomplete
      multiple
      // value={value}
      limitTags={2}
      getOptionLabel={(option) => option.label}
      filterSelectedOptions
      options={transformedCategoriesOptions}
      onChange={handleChange}
      // renderTags={(value: { label: string; value: string }[], getTagProps) =>
      //   value.map((option: { label: string; value: string }, index: number) => (
      //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //     // @ts-ignore 
      //     <Chip key={option?.value ?? index} label={option.label} {...getTagProps({ index })} />
      //   ))
      // }

      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            key={uuidv4()}
            label={option.label}
            {...getTagProps({ index })}
            disabled={transformedCategoriesOptions.indexOf(option) !== -1}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Select Categories"
          placeholder="Choose multiple categories" 
        />
      )}
    />
  );
};
