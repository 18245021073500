import { useRoutes } from "react-router-dom";

import { ErrorBoundary } from "react-error-boundary";

import AppFallbackComponent from "./components/fallback";

import { APP_ROUTES } from './routes';


// ##################################################################################################


function App() {
  const pages = useRoutes(APP_ROUTES);
  return (

    <ErrorBoundary FallbackComponent={({ error }) => <AppFallbackComponent error={error} />}>
      <>{pages}</>
    </ErrorBoundary>
  )
}

export default App;
