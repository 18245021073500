import { TextField, useTheme } from '@mui/material';

export const APPInputComponent = ({ label, name, placeholder, value, type, variant = 'outlined', onChange, disabled, customClasses }: any) => {

    const APP_THEME = useTheme();


    return (
        <TextField
            fullWidth
            type={type}
            name={name}
            label={label}
            value={value}
            variant={variant}
            onChange={onChange}
            disabled={disabled}
            placeholder={placeholder}
            className={`bg-[${APP_THEME.palette.grey[800]}] font-semibold relative ${customClasses}`}
            sx={{
                borderRadius: "12px",
                "&.MuiTextField-root": {
                    borderRadius: "12px",
                    fontWeight: 500,
                    fontSize: '14px',
                },

                "& .MuiOutlinedInput-input": {
                    padding: '12px'
                },

                "&.MuiOutlinedInput-root &.MuiOutlinedInput-input": {
                    borderRadius: "12px",
                },
                "&.MuiOutlinedInput-formControl": {
                    borderRadius: "12px",
                },

                "@media (min-width: 600px)": {
                    "& .MuiOutlinedInput-input": {
                        padding: '15px'
                    },
                },
            }}
        />
    )
}
