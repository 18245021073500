
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { RootState } from 'src/redux';

import { BASE_URL } from '../config';


export const TAGS = ['USER', 'PROFILE', 'BLOCK-USER'];


export const BASIC_AUTH = btoa("admin" + ":" + "passowrd");




// ##################################################################################################

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {

        const token = (getState() as RootState).auth.auth?.user?.token;

        console.log('token in base api ======== >>>>> ', token);


        headers.set('Authorization', `Basic ${BASIC_AUTH}`);

        if (token) {
            headers.set('token', `${token}`);
        }
        return headers;
    },
});



// ##################################################################################################

// const baseQueryWithRetry = retry(baseQuery, { maxRetries: 3 });

export const baseAPI = createApi({
    reducerPath: 'api',
    // baseQuery: baseQueryWithRetry,
    baseQuery: baseQuery,
    tagTypes: TAGS,
    endpoints: () => ({}),
    // keepUnusedDataFor: 1
    // refetchOnFocus: true,
    // refetchOnReconnect: true,

});



// export const { useLazyBaseQuery } = baseAPI;