interface FallbackProps {
    error: Error;
}

// ##################################################################################################


export default function AppFallbackComponent({ error }: FallbackProps) {
    return (
        <div>
            <h2>An error occurred:</h2>
            <p>{error.message}</p>
        </div>
    );
}
