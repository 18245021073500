import { baseAPI } from "../base-api";

import { APP_API_METHODS } from "src/constants";

import { APP_API_END_POINTS } from "../endpoints";



export const postAPI = baseAPI.injectEndpoints({
    endpoints: (builder) => ({



        postLike: builder.mutation({
            query: (body: any) => ({
                url: APP_API_END_POINTS.postLike,
                method: APP_API_METHODS.post,
                body,
            }),

        }),


        createPost: builder.mutation({
            query: (body: any) => ({
                url: APP_API_END_POINTS.createPost,
                method: APP_API_METHODS.post,
                body,
            }),

        }),


        writePost: builder.mutation({
            query: (body: any) => ({
                url: APP_API_END_POINTS.writePost,
                method: APP_API_METHODS.post,
                body,
            }),

        }),

        getCategoryBasedPost: builder.mutation({
            query: (body: any) => ({
                url: APP_API_END_POINTS.getCategoryPosts,
                method: APP_API_METHODS.post,
                body,
            }),

        }),


        getUserSavedPosts: builder.mutation({
            query: (body: any) => ({
                url: APP_API_END_POINTS.getSavedPosts,
                method: APP_API_METHODS.post,
                body,
            }),

        }),

        getPosts: builder.mutation({
            query: (body: any) => ({
                url: APP_API_END_POINTS.getPosts,
                method: APP_API_METHODS.post,
                body,
            }),

        }),

        getUserLikedPosts: builder.mutation({
            query: (body: any) => ({
                url: APP_API_END_POINTS.getLikedPosts,
                method: APP_API_METHODS.post,
                body,
            }),
        }),


        getUserCommentedPosts: builder.mutation({
            query: (body: any) => ({
                url: APP_API_END_POINTS.getCommentedPosts,
                method: APP_API_METHODS.post,
                body,
            }),
        }),



    }),
});

export const {
    usePostLikeMutation,
    useGetPostsMutation,
    useCreatePostMutation,
    useWritePostMutation,
    useGetUserLikedPostsMutation,
    useGetUserSavedPostsMutation,
    useGetUserCommentedPostsMutation,
    useGetCategoryBasedPostMutation
} = postAPI;
