import debounce from 'lodash.debounce';


// ##################################################################################################


export function isNullOrEmpty(
    value: string | null | undefined | unknown[] | Record<string, unknown>,
): boolean {
    if (value === undefined || value === null) {
        return true;
    }

    if (typeof value === 'string' && value.trim() === '') {
        return true;
    }

    if (Array.isArray(value) && value.length === 0) {
        return true;
    }

    if (typeof value === 'object' && Object.keys(value).length === 0) {
        return true;
    }

    return false;
}



// ##################################################################################################


// =============== DEBOUNCE SEARCH ===============

const DEBOUNCE_DELAY = 1000;
export const debouncedSearch = debounce((value: any, setSearchBy: any) => {
    setSearchBy(value);
}, DEBOUNCE_DELAY);






export const isAllowedToView = (userInfo: any, singleInterest: any) => {

    const isPremiumUser = userInfo?.isPremium; // Assuming userInfo contains user premium status
    const isInterestPremium = singleInterest?.ispremium; // Assuming singleInterest contains premium status of the interest

    // Determine if the user can view this interest based on premium status
    const canViewInterest = isPremiumUser ? isInterestPremium : !isInterestPremium;

    return canViewInterest;
};
