import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react';


import CssBaseline from '@mui/material/CssBaseline';


import reportWebVitals from './reportWebVitals';


import { APP_STORE, APP_PERSISTOR } from './redux';


import { SettingsProvider } from './context/use-settings';
import NotistackProvider from './context/notistack-provider';


import App from './App';
import AppProgressBarComponent from './components/progress-bar';



import ThemeProvider from 'src/theme';
import './styles/index.css';



// ##################################################################################################


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <Provider store={APP_STORE}>
        {/* // TODO: TO CHECK AND CONFIGURE THE LOADING STATE FOR REDUX PERSIST */}
        <PersistGate loading={<p className='text-white'>Settings this for you.</p>} persistor={APP_PERSISTOR}>
          <SettingsProvider>
            <ThemeProvider>
              <NotistackProvider>
                {/* // * CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <AppProgressBarComponent />
                <App />
              </NotistackProvider>
            </ThemeProvider>
          </SettingsProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();