import { Outlet } from 'react-router';


import DashboardLayout from './dashboard-layout';


// ##################################################################################################


export default function AppLayout() {
  return <DashboardLayout Outlet={<Outlet />} />
}
