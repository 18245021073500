import { APP_PATHS } from "./routes/paths";

export const AUTH_ACTION_TYPES = {
    setUserInfo: "SET_USER_INFO",
    updateUserInfo: "UPDATE_USER_INFO",
    setAuthenticated: "SET_AUTHENTICATED",
}


export const DRAWER_WIDTH = 320;


export const APP_INFO = {
    name: 'Memeworld Web App'
}



export const makeTitleName = (pageName: string) => {
    return `${pageName} | ${APP_INFO.name}`;
}




// ##################################################################################################


export const APP_PAGES_NAME = {
    home: 'Home',
    login: 'Login',
    getApp: 'Get App',
    profile: 'Profile',
    rewards: 'Rewards',
    settings: 'Settings',
    notFound: 'Not Found',
    categories: 'Categories'
}



// ##################################################################################################



export interface I_APP_HOME_TABS {
    id: React.Key,
    name: string,
    url: string
}




export const APP_HOME_TABS: I_APP_HOME_TABS[] = [
    {
        id: 0, // should always start from 0, because MUI TABS are handling it as INDEX number
        name: 'Trending',
        url: APP_PATHS.HOME.TRENDING
    },
    {
        id: 1,
        name: 'Latest',
        url: APP_PATHS.HOME.LATEST
    },
    {
        id: 2,
        name: 'Gifs',
        url: APP_PATHS.HOME.GIFS
    },
    {
        id: 3,
        name: 'Videos',
        url: APP_PATHS.HOME.VIDEOS
    }
]



// ##################################################################################################


export const APP_API_METHODS = {
    put: 'PUT',
    get: 'GET',
    post: 'POST',

}