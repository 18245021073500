import { createSlice } from "@reduxjs/toolkit";

import { AUTH_ACTION_TYPES } from "src/constants";
import { authAPI } from "src/services/auth";


import { I_AUTH_INITIAL_STATE } from "src/types/store";




const initialState: I_AUTH_INITIAL_STATE = {
    isAuthenticated: false,
    requestedPath: null,
    user: {}
};





// ##################################################################################################

export const loginSuccess = (state: I_AUTH_INITIAL_STATE, action: any) => {
    const { type, payload } = action.payload;

    switch (type) {
        case AUTH_ACTION_TYPES.setAuthenticated:
            return {
                ...state,
                isAuthenticated: payload,
            };

        case AUTH_ACTION_TYPES.setUserInfo:
            return {
                ...state,
                user: payload,
            };

        case AUTH_ACTION_TYPES.updateUserInfo:
            return {
                ...state,
                user: { ...state.user, result: payload },
            };
        default:
            return state;
    }

};



export const registerSuccess = (state: I_AUTH_INITIAL_STATE) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const updatedState = state = initialState;

    return updatedState
};




// ##################################################################################################






const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        APP_LOGIN: (state, action) => loginSuccess(state, action),
        APP_USER_GET: (state) => state,
        APP_LOGOUT: (state) => registerSuccess(state),
        updateUserInfo(state, action) {
            const { payload } = action;
            return {
                ...state,
                user: {
                    ...state.user,
                    ...payload,
                },
            };
        },
    },



    extraReducers: (builder) => {
        builder
            // LOGIN MATCHER
            .addMatcher(authAPI.endpoints.login.matchFulfilled, loginSuccess)
            // VERIFY OTP MATCHER
            .addMatcher(authAPI.endpoints.verifyOtp.matchFulfilled, registerSuccess);
    },


});

export const { APP_LOGIN, APP_USER_GET, APP_LOGOUT, updateUserInfo } = authSlice.actions;
export default authSlice.reducer;