import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';


import { v4 as uuidv4 } from 'uuid';





export default function SliderComponent({ data }: any) {
    const [currentIndex, setCurrentIndex] = useState(0);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setCurrentIndex((prevIndex) =>
    //             prevIndex === data.length - 1 ? 0 : prevIndex + 1
    //         );
    //     }, 2000);

    //     return () => clearInterval(interval);
    // }, [data.length]);




    const goToPreviousSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? data.length - 1 : prevIndex - 1
        );
    };

    const goToNextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === data.length - 1 ? 0 : prevIndex + 1
        );
    };






    return (
        <div className="relative w-full lg:h-[400px]">
            {data.map((singleItem: any, index: number) => (
                <div
                    key={uuidv4()}
                    className={` top-0 w-full h-full ${index === currentIndex ? 'left-0' : 'left-[-100%]'}`}
                    style={{
                        transition: 'left 0.5s ease'
                    }}
                >
                    <span className='block w-[150px] h-[150px] m-auto'>
                        <img src={singleItem?.img} alt={`Slide ${index}`} className='w-full h-full object-contain' />
                    </span>
                    <div className='text-center m-auto max-w-[400px] mt-4 lg:mt-10'>
                        <Typography variant='h4'>{singleItem?.title}</Typography>
                        <Typography variant='body1' className='text-[#BCBCBC]'>{singleItem?.desc}</Typography>
                    </div>
                </div>
            ))}
        </div>
    );
}