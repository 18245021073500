export const makePath = (root: string, sublink: string) => {
  return `${root}${sublink}`;
}



// ##################################################################################################

export const APP_PATHS = {
  INDEX: '/',
  LOGIN: 'login',
  HOME: {
    GIFS: 'gifs',
    INDEX: 'home',
    VIDEOS: 'videos',
    LATEST: 'latest',
    TRENDING: 'trending'
  },
  REWARDS: 'rewards',
  GET_APP: 'get-app',
  SUBSCRIPTION: 'subscription',
  // PROFILE: {
  //   INDEX: 'profile',
  //   POSTS: 'posts',
  //   LIKED: 'liked',
  //   COMMENTS: 'comments',
  // },
  PROFILE: 'profile',
  SETTINGS: 'settings',
  CATEGORIES: 'categories',
  COMPONENT: 'component',
}