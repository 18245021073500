export const AppLikeIcon = ({ active }: { active?: boolean }) => {
    return (
        <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.99079 11.9C6.40363 10.6066 5.11078 9.77946 3.69039 9.7884C1.73139 9.76252 0.122155 11.3293 0.095702 13.2883L0.0957014 20.2915C0.126693 22.2472 1.73458 23.809 3.69039 23.7831C5.23319 23.7953 6.61104 22.8198 7.11217 21.3606M6.99084 11.8996C7.18531 12.3366 7.28558 12.8096 7.28513 13.2879L7.28513 20.2911C7.28767 20.6563 7.23153 21.0195 7.11887 21.3668M6.99121 11.8996C7.27144 11.3365 7.67357 10.843 8.16838 10.4547C8.46965 10.2344 8.69041 9.92133 8.79687 9.56355L10.5942 2.58034C11.0334 1.07108 12.6001 0.19126 14.1174 0.601763C14.8453 0.780268 15.4708 1.24393 15.8532 1.88843C16.2357 2.53293 16.3429 3.30413 16.1509 4.02853L15.1233 7.75789C15.0131 8.18393 15.1084 8.63699 15.3809 8.98256C15.6533 9.32812 16.0717 9.52645 16.5117 9.51866L22.3842 9.51866C23.5134 9.50312 24.6025 9.93696 25.4117 10.7247C26.2209 11.5124 26.6839 12.5894 26.6988 13.7186L26.6988 19.3168C26.6835 20.4457 26.2203 21.5223 25.4111 22.3096C24.6019 23.0969 23.5131 23.5306 22.3842 23.515L10.8802 23.515C9.32917 23.5229 7.89225 22.701 7.11258 21.3602"
                fill={active ? '#E2B248' : "#CECECE"}
            />
            <line x1="7.19285" y1="25.2471" x2="7.19286" y2="8.17357" stroke="#341C1F" strokeWidth="1.75882" />
        </svg>

    )
}



export const AppDisLikeIcon = ({ active }: { active?: boolean }) => {
    return (
        <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M20.3842 14.1244C20.9714 15.4178 22.2642 16.245 23.6846 16.236C25.6436 16.2619 27.2528 14.6951 27.2793 12.7361L27.2793 5.73293C27.2483 3.77719 25.6404 2.21541 23.6846 2.24132C22.1418 2.22916 20.764 3.20463 20.2628 4.66383M20.3842 14.1248C20.1897 13.6878 20.0895 13.2148 20.0899 12.7365L20.0899 5.73332C20.0874 5.36815 20.1435 5.00494 20.2562 4.65757M20.3838 14.1248C20.1036 14.6879 19.7014 15.1814 19.2066 15.5697C18.9053 15.7901 18.6846 16.1031 18.5781 16.4609L16.7808 23.4441C16.3416 24.9533 14.7749 25.8332 13.2576 25.4227C12.5297 25.2441 11.9042 24.7805 11.5218 24.136C11.1393 23.4915 11.0321 22.7203 11.2241 21.9959L12.2517 18.2665C12.3619 17.8405 12.2666 17.3874 11.9941 17.0419C11.7217 16.6963 11.3033 16.498 10.8633 16.5058L4.99079 16.5058C3.8616 16.5213 2.77252 16.0875 1.9633 15.2998C1.15408 14.512 0.691055 13.4351 0.676165 12.3059L0.676165 6.70764C0.691494 5.57873 1.15471 4.50216 1.96388 3.71481C2.77305 2.92747 3.86188 2.49386 4.99079 2.5094L16.4948 2.5094C18.0458 2.50156 19.4827 3.32338 20.2624 4.66422"
                fill={active ? '#E2B248' : "#CECECE"}
            />
            <line x1="20.1821" y1="0.777344" x2="20.1821" y2="17.8508" stroke="#341C1F" strokeWidth="1.75882" />
        </svg>
    )
}

export const AppArrowLeftIcon = () => {
    return (
        <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.80139 1.44434L2.07544 8.17028L8.80139 14.8962" stroke="white" strokeWidth="2.24198" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}


