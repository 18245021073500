import React from 'react'
import { useNavigate } from 'react-router';


import { Box, Tab, Tabs } from '@mui/material'


import { v4 as uuidv4 } from 'uuid';


import { isNullOrEmpty } from 'src/utils';
import { I_APP_HOME_TABS } from 'src/constants';



interface I_TABS {
  tabValue: string | number | bigint | undefined,
  tabs: I_APP_HOME_TABS[],
}


interface I_LINK_TAB_PROPS {
  label?: string;
  href: string;
}



// ##################################################################################################

export function samePageLinkNavigation(
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) {
  if (
    event.defaultPrevented ||
    event.button !== 0 || // ignore everything but left-click
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false;
  }
  return true;
}



// ##################################################################################################


export function LinkTab(props: I_LINK_TAB_PROPS) {
  const navigate = useNavigate();
  return (
    <Tab
      component="a"
      sx={{
        fontSize: { xs: '14px', md: '16px', lg: '20px' },

        "&.MuiTab-root": {
          minWidth: 10
        },

        "&.MuiButtonBase-root": {
          minWidth: 10
        },


        "&.MuiButtonBase-root.MuiTab-root ": {
          minWidth: 10
        },

      }}


      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {

        // Routing libraries handle this, you can remove the onClick handle when using them.

        if (samePageLinkNavigation(event)) {
          event.preventDefault();


          // TODO: MAKE HOME PATH DYNAMIC TOO

          const newPath = `/home/${props.href}`;
          const newState = { 'tab': props.label }; // Include any additional state properties
          navigate(newPath, { state: { location: newState } });
        }
      }}
      {...props}
    />
  );
}



// ##################################################################################################


export default function AppTabsComponent({ tabValue, tabs }: I_TABS) {

  return (
    <Box sx={{
      borderBottom: 1,
      borderColor: 'divider',
      width: '100%'
    }}>
      <Tabs
        value={tabValue ?? 0}
        variant='fullWidth'
        // allowScrollButtonsMobile
        className='w-full'
        // scrollButtons="auto"
        sx={{
          maxWidth: "100%",
          margin: "0 auto",
        }}
      >
        {!isNullOrEmpty(tabs) && tabs.map((singleTab: I_APP_HOME_TABS) => (
          <LinkTab key={uuidv4()} label={singleTab.name} href={singleTab.url} />
        ))}
      </Tabs>
    </Box>
  )
}
