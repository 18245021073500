
import { Card, CardContent, CardHeader, Skeleton } from '@mui/material'



// ##################################################################################################



export const AppPostSkeletonComponent = () => {


    return (
        <div className='relative' >
            <Card className="w-full relative">
                <div
                    role='button'
                    tabIndex={0}
                    className='w-max block px-[1rem] lg:px-none mt-4'>
                    <CardHeader
                        avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
                        title={<Skeleton
                            animation="wave"
                            height={25}
                            width="30%"
                            style={{ marginBottom: 1 }}
                        />}
                        subheader={<Skeleton animation="wave" variant='rectangular' height={25} width="60%" />}

                        sx={{ padding: 0 }}
                    />
                </div>
                <div className='ml-4 lg:ml-0 mt-4 lg:px-4'>
                    <Skeleton animation="wave" height={25} width="80%" />
                </div>



                <div className='ml-4 lg:ml-0 mt-4 lg:px-4'>
                    <Skeleton animation="wave" height={25} width="80%" />
                </div>

                <div className='ml-4 lg:ml-0 mt-4 lg:px-4'>
                    <Skeleton animation="wave" height={25} width="80%" />
                </div>


                <div className='flex items-center gap-4 flex-wrap m-4'>
                    <Skeleton animation="wave" variant='rectangular' height={25} width="10%" />
                    <Skeleton animation="wave" variant='rectangular' height={25} width="10%" />
                </div>
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    className='mx-4 w-auto'
                    sx={{ height: "330px" }}
                />
                <CardContent>
                    <div className='flex items-center justify-between gap-4'>
                        <Skeleton animation="wave" variant="rounded" width="25%" height={40} />
                        <Skeleton animation="wave" variant="rounded" width="25%" height={40} />
                        {/* <Skeleton animation="wave" variant="rounded" width="25%" height={40} /> */}
                        <Skeleton animation="wave" variant="rounded" width="25%" height={40} />
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}