// src/redux/slices/userPostInteractionsSlice.ts

import { createSlice } from '@reduxjs/toolkit';


// export interface Comment {
//     id: number;
//     text: string;
// }

// export interface Post {
//     id: number;
//     title: string;
//     content: string;
//     likes: number;
//     dislikes: number;
//     comments: Comment[];
//     path?: string
// }

// export interface PostsState {
//     posts: Post[];
// }


const initialState: any = {
    posts: [],
};

const userPostInteractionsSlice = createSlice({
    name: 'userInteractions',
    initialState,
    reducers: {

        // ALL_POST(state, action: any) {

        //     const { data, path } = action.payload;

        //     // Filter out existing posts that do not belong to the specified path
        //     const existingPosts = state.posts.filter((post: any) => !post.path || post.path !== path);


        //     // Map new posts and add path property
        //     const newPosts = data?.post_data.map((post: any) => ({ ...post, path }));


        //     // Merge existing and new posts while preserving other data
        //     state.posts = [...existingPosts, ...newPosts];

        // },

        // ALL_POST(state, action: any) {
        //     const { data, path } = action.payload;

        //     // Find existing posts for the specified path
        //     const existingPostsForPath = state.posts.filter((post: any) => post.path === path);


        //     console.log('data =========== >>>> ',path);
        //     console.log('existingPostsForPath =========== >>>> ',existingPostsForPath);
        //     console.log('data =========== >>>> ',data);



        //     // If there are existing posts for the same path, update them with the new data
        //     if (existingPostsForPath.length > 0) {
        //         // Update existing posts with new data (if any)
        //         const updatedPostsForPath = existingPostsForPath.map((existingPost: any) => {
        //             const matchingNewPost = data?.post_data.find((newPost: any) => newPost.postId === existingPost.postId);
        //             // If a matching new post is found, merge it with the existing post
        //             return matchingNewPost ? { ...existingPost, ...matchingNewPost } : existingPost;
        //         });

        //         // Merge updated posts with other posts
        //         state.posts = state.posts.map((post: any) => {
        //             // If the post belongs to the specified path, replace it with the updated post
        //             if (post.path === path) {
        //                 return updatedPostsForPath.find((updatedPost: any) => updatedPost.postId === post.postId) || post;
        //             }
        //             return post;
        //         });
        //     } else {
        //         // If there are no existing posts for the specified path, add new posts to the list
        //         const newPosts = data?.post_data.map((post: any) => ({ ...post, path }));
        //         state.posts = [...state.posts, ...newPosts];
        //     }
        // },


        // ALL_POST(state, action) {
        //     const { data, path } = action.payload;

        //     // Check if there are existing posts for the same path
        //     const existingPostsForPath = state.posts.filter((post: any) => post.path === path);

        //     // Update posts based on whether there are existing posts for the path
        //     if (existingPostsForPath.length > 0) {
        //         // If there are existing posts, update them with new data
        //         state.posts = state.posts.map((post: any) => {
        //             if (post.path === path) {
        //                 // Find the corresponding post from the new data
        //                 const updatedPost = data.post_data.find((newPost: any) => newPost.postId === post.postId);
        //                 // If found, merge the existing post with the updated post
        //                 return updatedPost ? { ...post, ...updatedPost } : post;
        //             }
        //             return post;
        //         });
        //     } else {
        //         // If there are no existing posts, simply add the new posts to the list
        //         state.posts = [...state.posts, ...data.post_data.map((post: any) => ({ ...post, path }))];
        //     }
        // },


        // ALL_POST(state, action) {
        //     const { data, path } = action.payload;

        //     // Check if there are existing posts for the same path
        //     const existingPostsForPath = state.posts.filter((post: any) => post.path === path);

        //     // If no existing posts exist for the path, simply add new posts to the list
        //     if (existingPostsForPath.length === 0) {
        //         state.posts = [...state.posts, ...data.post_data.map((post: any) => ({ ...post, path }))];
        //         return;
        //     }

        //     // Update existing posts with new data
        //     state.posts = state.posts.map((post: any) => {
        //         if (post.path === path) {
        //             // Find the corresponding post from the new data
        //             const updatedPost = data.post_data.find((newPost: any) => newPost.postId === post.id);
        //             // If found, merge the existing post with the updated post
        //             return updatedPost ? { ...post, ...updatedPost } : post;
        //         }
        //         return post;
        //     });
        // },

        ALL_POST(state, action) {
            const { data, path } = action.payload;
            // Append new posts to the existing list
            state.posts = [...state.posts, ...data.post_data.map((post: any) => ({ ...post, path }))];
        },

        ADD_POST(state, action: any) {
            const { id } = action.payload;
            const existingPost = state.posts.find((post: any) => post.id === id);
            if (!existingPost) {
                state.posts.push(action.payload);
            }
        },

        UPDATE_POST(state, action: any) {
            const { id } = action.payload;
            const existingPost = state.posts.find((post: any) => post.id === id);
            if (existingPost) {
                Object.assign(existingPost, action.payload);
            } else {
                state.posts.push(action.payload);
            }
        },
        ADD_COMMENT(state, action: any) {
            const { postId, comment } = action.payload;
            const post = state.posts.find((post: any) => post.id === postId);
            if (post) {
                post.comments.push(comment);
            }
        },
        LIKE_POST(state, action: any) {
            const postId = action.payload;
            const post = state.posts.find((post: any) => post.postId === postId);

            if (post) {
                if (post.likeFlag === 0) {
                    // If the post was neither liked nor disliked, apply like
                    post.likes_count += 1;
                    post.likeFlag = 1; // Set likeFlag to 1 (indicating the post is liked)
                }
                else if (post.likeFlag === 1) {
                    // If the post has been liked before, unlike it
                    post.likes_count -= 1;
                    post.likeFlag = 0; // Set likeFlag to 0 indicate post has been set to default
                }
                else if (post.likeFlag === 2) {
                    // If the post was previously liked, unlike it
                    post.dislike_count -= 1;
                    post.likes_count += 1;
                    post.likeFlag = 1; // Set likeFlag to indicate post has been liked
                }
            }
        },
        DISLIKE_POST(state, action: any) {
            const postId = action.payload;
            const post = state.posts.find((post: any) => post.postId === postId);
            if (post) {

                if (post.likeFlag === 0) {
                    // If the post was neither liked nor disliked, apply dislike
                    post.dislike_count += 1;
                    post.likeFlag = 2; // Set likeFlag to 2 (indicating the post is disliked)
                }

                else if (post.likeFlag === 1) {
                    // If the post was previously liked, undo the like and apply dislike
                    post.likes_count -= 1;
                    post.dislike_count += 1;
                    post.likeFlag = 2; // Set likeFlag to 2 (indicating the post is disliked)

                } else if (post.likeFlag === 2) {
                    // If the post was previously disliked, undo the dislike
                    post.dislike_count -= 1;
                    post.likeFlag = 0; // Reset likeFlag to 0 (or remove it if not needed)
                }

            }
        },
    },
});

export const {
    ALL_POST,
    ADD_POST,
    UPDATE_POST,
    ADD_COMMENT,
    LIKE_POST,
    DISLIKE_POST,
} = userPostInteractionsSlice.actions;

export default userPostInteractionsSlice.reducer;
