// TypedUseSelectorHook is not deprecated, see => https://github.com/reduxjs/react-redux/issues/1678
// eslint-disable-next-line import/named
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from 'redux-persist';


import { baseAPI } from '../services/base-api';

import authSlice from "./slices/auth"
import userPostInteractionsSlice from "./slices/posts"
import { setupListeners } from '@reduxjs/toolkit/query';


// ##################################################################################################



const PERSIST_CONFIG = {
    key: "ZENIN_TOJI_WORLD", // ? If you don't know the ZENIN_TOJI, don't change the below key.
    storage,
};


const reducer = combineReducers({
    auth: authSlice,
});


const persistedReducer = persistReducer(PERSIST_CONFIG, reducer);



// ##################################################################################################

const APP_STORE = configureStore({
    reducer: {
        auth: persistedReducer,
        userPostInteractionsSlice: userPostInteractionsSlice,
        [baseAPI.reducerPath]: baseAPI.reducer,
    },
    middleware: (defaultMiddleware) =>
        defaultMiddleware({ serializableCheck: false }).concat(baseAPI.middleware),
});


const APP_PERSISTOR = persistStore(APP_STORE);

// enable listener behavior for the store
setupListeners(APP_STORE.dispatch)


export type AppDispatch = typeof APP_STORE.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type RootState = ReturnType<typeof APP_STORE.getState>;
export { APP_STORE, APP_PERSISTOR };

