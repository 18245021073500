import { TAGS, baseAPI } from "../base-api";

import { APP_API_METHODS } from "src/constants";

import { APP_API_END_POINTS } from "../endpoints";
import { API_TAGS } from "../api-tags";



export const authAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({


    // ### LOGOUT ###
    logout: builder.mutation<null, void>({
      queryFn: () => ({ data: null }),
      invalidatesTags: TAGS,
    }),



    // ### LOGIN ###
    login: builder.mutation({
      query: (credentials: any) => ({
        url: APP_API_END_POINTS.login,
        method: APP_API_METHODS.post,
        body: credentials,
      }),
    }),



    // ### VERIFY OTP ###
    verifyOtp: builder.mutation({
      query: (credentials: any) => ({
        url: APP_API_END_POINTS.verifyOtp,
        method: APP_API_METHODS.post,
        body: credentials,
      }),
    }),
    
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useVerifyOtpMutation
} = authAPI;
