import React from "react";


import { Navigate, useLocation } from "react-router";
import { RootState, useAppSelector } from "src/redux";



// ##################################################################################################

export const AuthGuard = ({ children }: { children: React.ReactNode }) => {

    const location = useLocation();
    
    const { isAuthenticated } = useAppSelector((state: RootState) => state.auth.auth);

    return isAuthenticated === true ? <>{children}</> : <Navigate to="/login" replace state={{ path: location.pathname }} />

}
