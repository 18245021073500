import { useEffect } from "react";
import NProgress from "nprogress";

// @mui
import { useTheme } from "@mui/material/styles";
import GlobalStyles from "@mui/material/GlobalStyles";
import { useNavigate } from "react-router-dom";



// ##################################################################################################


export default function AppProgressBarComponent() {
    const theme = useTheme();
    const navigate = useNavigate();


    NProgress.configure({ showSpinner: false });

    useEffect(() => {

        const handleStart = () => {
            NProgress.start();
        };
        const handleStop = () => {
            NProgress.done();
        };

        window.addEventListener('routeChangeStart', handleStart);
        window.addEventListener('routeChangeComplete', handleStop);
        window.addEventListener('routeChangeError', handleStop);

        return () => {
            window.removeEventListener('routeChangeStart', handleStart);
            window.removeEventListener('routeChangeComplete', handleStop);
            window.removeEventListener('routeChangeError', handleStop);
        };
    }, [navigate]);



    
    return (
        <GlobalStyles
            styles={{
                "#nprogress": {
                    pointerEvents: "none",
                    "& .bar": {
                        top: 0,
                        left: 0,
                        height: 2,
                        width: "100%",
                        position: "fixed",
                        zIndex: theme.zIndex.snackbar,
                        backgroundColor: theme.palette.primary.main,
                        boxShadow: `0 0 2px ${theme.palette.primary.main}`,
                    },
                    "& .peg": {
                        right: 0,
                        opacity: 1,
                        width: 100,
                        height: "100%",
                        display: "block",
                        position: "absolute",
                        transform: "rotate(3deg) translate(0px, -4px)",
                        boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`,
                    },
                },
            }}
        />
    );
}
