export const APP_API_END_POINTS = {
  login: "login",
  verifyOtp: "verifyOtp",
  sendSubscriptionOTP: "sendSubscriptionOTP",
  checkSubscriberStatus: "checkSubscriberStatus",
  verifySubscriptionOTP: "verifySubscriptionOTP",
  unSubscribe: "unSubscribe",

  getInterests: "interests",
  getCategoryPosts: "getCategoryPosts",


  
  // PROFILE
  getProfile: "getProfile",
  setProfile: "setProfile",
  getPackages: "getPackages",
  getBlockedUser: "getBlockUser",
  
  // POST
  likePost: "likepost",
  postLike: "likepost",
  createPost: "createpost",
  writePost: "writepost",
  getTrendingPosts: 'trending',
  getLatestPosts: 'latest',
  getVideoPosts: 'videos',
  getGifsPosts: 'gifs',
  getSavedPosts: 'getSavedPosts',
  getPosts: 'getposts',
  getLikedPosts: 'getLikedPosts',
  getCommentedPosts: 'getCommentedPosts',

  // REWARDS
  getReward: "getGratifications",
  claimReward: "userClaimReward",
};
