import { FC, lazy, LazyExoticComponent, Suspense } from 'react';
import { Navigate } from 'react-router';

import { APP_PATHS, makePath } from './paths';


import AppLayout from 'src/layout';
import { AuthGuard } from 'src/guards/auth-guard';
import AppHomeLayout from 'src/layout/home-layout';



// ##################################################################################################


const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
(
  <Suspense
    fallback={
      <div
        className="d-flex justify-center align-center"
        style={{ height: '80vh' }}
      >
        {/* <img src={LoadingSvg} height={200} width={200} alt="LoadingSvg" /> */}
      </div>
    }
  >
    <Component {...props} />
  </Suspense>
);


// ##################################################################################################


const HOME_PAGE = Loadable(lazy(() => import('../pages/home')));
const LOGIN_PAGE = Loadable(lazy(() => import('../pages/login')));
const GET_APP_PAGE = Loadable(lazy(() => import('../pages/get-app')));
const NOT_FOUND_PAGE = Loadable(lazy(() => import('../pages/not-found')));
const REWARDS_PAGE = Loadable(lazy(() => import('../pages/rewards')));
const SETTINGS_PAGE = Loadable(lazy(() => import('../pages/settings')));
const PROFILE_PAGE = Loadable(lazy(() => import('../pages/profile')));
const CATEGORIES_PAGE = Loadable(lazy(() => import('../pages/categories')));
const COMPONENT_SECTION = Loadable(lazy(() => import('../sections/components')));




// ##################################################################################################

export const APP_ROUTES = [
  { path: APP_PATHS.INDEX, element: <Navigate to={APP_PATHS.HOME.INDEX} /> },
  {
    path: makePath(APP_PATHS.INDEX, APP_PATHS.LOGIN),
    element: <LOGIN_PAGE />
  },
  {
    path: makePath(APP_PATHS.INDEX, APP_PATHS.COMPONENT),
    element: <COMPONENT_SECTION />
  },
  {
    path: APP_PATHS.INDEX,
    element: <AppLayout />,
    children: [
      { path: APP_PATHS.HOME.INDEX, element: <Navigate to={APP_PATHS.HOME.TRENDING} /> },

      {
        path: APP_PATHS.HOME.INDEX,
        element: (
          <AuthGuard>
            <AppHomeLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: APP_PATHS.HOME.TRENDING,
            element: (
              <AuthGuard>
                <HOME_PAGE />
              </AuthGuard>
            )
          },
          {
            path: APP_PATHS.HOME.LATEST,
            element: (
              <AuthGuard>
                <HOME_PAGE />
              </AuthGuard>
            )
          },
          {
            path: APP_PATHS.HOME.GIFS,
            element: (
              <AuthGuard>
                <HOME_PAGE />
              </AuthGuard>
            )
          },
          {
            path: APP_PATHS.HOME.VIDEOS,
            element: (
              <AuthGuard>
                <HOME_PAGE />
              </AuthGuard>
            )
          }
        ]
      },
      {
        path: APP_PATHS.GET_APP,
        element: (
          <AuthGuard>
            <GET_APP_PAGE />
          </AuthGuard>
        ),
      },
      {
        path: APP_PATHS.REWARDS,
        element: (
          <AuthGuard>
            <REWARDS_PAGE />
          </AuthGuard>
        ),
      },
      {
        path: APP_PATHS.SETTINGS,
        element: (
          <AuthGuard>
            <SETTINGS_PAGE />
          </AuthGuard>
        ),
      },

      {
        path: APP_PATHS.PROFILE,
        element: (
          <AuthGuard>
            <PROFILE_PAGE />
          </AuthGuard>
        ),
      },
      {
        path: APP_PATHS.SUBSCRIPTION,
        element: (
          <AuthGuard>
            <SETTINGS_PAGE />
          </AuthGuard>
        ),
      },

      {
        path: APP_PATHS.CATEGORIES,
        element: (
          <AuthGuard>
            <CATEGORIES_PAGE />
          </AuthGuard>
        ),
      },
    ]
  },
  {
    path: "*",
    element: <NOT_FOUND_PAGE />
  }
]