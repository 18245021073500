import { useEffect, useRef, useState } from 'react';

import { Avatar, Box, Card, CardContent, CardHeader, CardMedia, Menu, MenuItem, Skeleton, Typography } from '@mui/material'



import { APP_ASSETS } from 'src/assets';
import { appFormatTimeAgo } from 'src/utils/getDataTime';


import { v4 as uuidv4 } from 'uuid';
import { AppDisLikeIcon, AppLikeIcon } from 'src/assets/svg';
import { AppContentOverlay } from './content-overlay';
import { RootState, useAppDispatch, useAppSelector } from 'src/redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_PATHS } from 'src/routes/paths';




import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { FacebookIcon, TwitterIcon, WhatsappIcon } from 'react-share';
import { enqueueSnackbar } from 'notistack';
import { DISLIKE_POST, LIKE_POST } from 'src/redux/slices/posts';
import { usePostLikeMutation } from 'src/services/post';
import { useLogout } from 'src/hooks';


interface I_POST_COMPONENT_PROPS {
    key?: string,
    id?: string,
    loading: boolean,
    data: any,
    style?: any,
    currentPathUrl?: string,
    handleLike?: any,
    handleDisLike?: any,
    handleComment?: any,
    handleShare?: any
}


// ##################################################################################################



export const AppPostComponent = ({ id, loading: LOADING, style, data, handleLike, handleDisLike, handleComment, handleShare }: I_POST_COMPONENT_PROPS) => {

    const videoRef = useRef<any>(null);

    const location = useLocation();
    const navigate = useNavigate();

    const { user: userInfo } = useAppSelector((state: RootState) => state.auth.auth)


    const [isOpenShare, setIsOpenShare] = useState<HTMLElement | null>(null);


    const open = Boolean(isOpenShare);
    const [postLike] = usePostLikeMutation()

    const handleLogout = useLogout();

    const isPremiumContent = data?.isPremium;
    const isUserPremium = userInfo?.isPremium;


    const postColors = data?.postColors || ""; // Assuming postColors is provided in your data object
    const colorsArray = postColors.split(','); // Split the string into an array of colors



    const dispatch = useAppDispatch();


    const handleLikedClicked = async (postData: any) => {


        console.log('postData in handleLikedClicked', postData);


        const body = {
            postid: postData?.postId,
            status: 1
        }

        try {
            const getLikePostResponse = await postLike({ ...body }).unwrap();


            console.log('getLikePostResponse', getLikePostResponse);


            if (getLikePostResponse?.message === 'Token Not Found') {
                enqueueSnackbar(`Session Expired, Login again.`, { variant: "error" });
                handleLogout();
            }



            // isReloadHomeData(true);



            dispatch(LIKE_POST(postData?.postId))



        } catch (error: any) {
            console.log('error in posting like ======== >>>> ', error);
        }

    }


    const handleDisLikedClicked = async (postData: any) => {


        console.log('postData in handleDisLikedClicked', postData);


        const body = {
            postid: postData?.postId,
            status: 2
        }

        try {
            const getDisLikePostResponse = await postLike({ ...body }).unwrap();


            console.log('getDisLikePostResponse', getDisLikePostResponse);


            if (getDisLikePostResponse?.message === 'Token Not Found') {
                enqueueSnackbar(`Session Expired, Login again.`, { variant: "error" });
                handleLogout();
            }


            dispatch(DISLIKE_POST(postData?.postId))


        } catch (error: any) {
            console.log('error in posting like ======== >>>> ', error);
        }

    }


    const handleCommentClicked = async (postData: any) => {
        enqueueSnackbar("This feature is coming soon!", { variant: 'info' });
    }





    // Ensure each color has a hash at the beginning
    const formattedColorsArray = colorsArray.map((color: string) => `#${color.trim()}`);


    const colorsPercentage = [0.0, 0.0, 0.0, 0.6, 0.9];

    // Generate the gradient string dynamically based on percentages
    const gradientStops = formattedColorsArray.map((color: string, index: number) => `${color} ${colorsPercentage[index] * 100}%`);
    const gradient = `linear-gradient(to bottom right, ${gradientStops.join(', ')})`;


    const handleOpenShare = (event: React.MouseEvent<HTMLElement>) => {
        setIsOpenShare(event.currentTarget);
    };


    const handleCloseShare = () => {
        setIsOpenShare(null);
    };



    // const postShareDeepLink = `https://memeworld.page.link/singlePostByIdScreens/${data?.postId}?apn=com.memeworld.funny.hilarious.memes&ibi=com.memeworld.funny.memes&isi=6476913158`;
    const postShareDeepLink = `https://onelink.to/m6syjc`;


    const handleLinkClick = () => {
        // Check if the current location is already the profile page
        if (location.pathname !== `/${APP_PATHS.PROFILE}`) {
            // Navigate the user to the profile page only if it's not already the current location
            // Also, pass the sensitive data in the state object
            navigate(`/${APP_PATHS.PROFILE}?id=${id}`, {
                state: data
            });
        }
    };


    const handleUnlockContent = () => {
        navigate(`/${APP_PATHS.SUBSCRIPTION}`, {
            state: data
        });
    }



    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (videoRef.current) {
                        videoRef.current.play();
                    }
                } else {
                    if (videoRef.current) {
                        videoRef.current.pause();
                    }
                }
            });
        }, {
            threshold: 0.5,
        });

        if (videoRef.current && data?.postLink) {
            observer.observe(videoRef.current);
        }

        return () => {
            if (videoRef.current) {
                observer.unobserve(videoRef.current);
            }
        };
    }, []);

    // style={style}
    return (
        <div className='relative' >
            <Card className="w-full relative">
                <div
                    role='button'
                    tabIndex={0}
                    className='w-max block px-[1rem] lg:px-none mt-4' onClick={() => handleLinkClick()}>
                    <CardHeader
                        avatar={
                            LOADING ? (
                                <Skeleton animation="wave" variant="circular" width={40} height={40} />
                            ) : (
                                <Avatar
                                    alt={data?.userName}
                                    src={data?.profilePictureLink}
                                />
                            )
                        }
                        // action={
                        //     LOADING ? null : (
                        //         <IconButton aria-label="settings">
                        //             <MoreVertIcon />
                        //         </IconButton>
                        //     )
                        // }
                        title={
                            LOADING ? (
                                <Skeleton
                                    animation="wave"
                                    height={25}
                                    width="30%"
                                    style={{ marginBottom: 1 }}
                                />
                            ) : (
                                <Typography variant="h6" color="text.primary">
                                    {data?.userName}
                                </Typography>
                            )
                        }
                        // subheader={
                        //     LOADING ? (
                        //         <Skeleton animation="wave" height={25} width="60%" />
                        //     ) : appFormatTimeAgo(data?.createdTime)
                        // }

                        sx={{ padding: 0 }}
                    />
                </div>
                <div className='mt-4 lg:px-4'>
                    {
                        LOADING ? (
                            <Skeleton animation="wave" height={25} width="80%" />
                        ) : (
                            <Typography variant="body1" color="text.primary" className='px-4'>
                                {data?.postDesc}
                            </Typography>
                        )
                    }
                </div>



                <div className='mt-4 lg:px-4'>
                    {
                        LOADING ? (
                            <Skeleton animation="wave" height={25} width="80%" />
                        ) : (
                            <div className='px-4 flex flex-wrap items-center gap-4 w-full'>
                                {data?.hashtags?.map((singleHashTag: any) => {
                                    return (
                                        <div key={uuidv4()} className=' text-white rounded-xl border-white border'>
                                            <p className='px-4 text-blue-400'>
                                                #{singleHashTag?.hashtag_name}
                                            </p>
                                        </div>
                                    )
                                })}

                            </div>
                        )
                    }
                </div>

                <div className='mt-4 lg:px-4'>
                    {
                        LOADING ? (
                            <Skeleton animation="wave" height={25} width="80%" />
                        ) : (
                            <div className='px-4 flex flex-wrap items-center gap-4 w-full'>
                                {data?.categories?.map((singleCategory: any) => {
                                    return (
                                        <div key={uuidv4()} className='bg-white text-black rounded-xl'>
                                            <p className='px-4'>
                                                {singleCategory?.category_name}
                                            </p>
                                        </div>
                                    )
                                })}

                            </div>
                        )
                    }
                </div>


                <div className='flex items-center gap-4 flex-wrap m-4'>

                    {
                        LOADING ? (
                            <>
                                <Skeleton animation="wave" variant='rectangular' height={25} width="10%" />
                                <Skeleton animation="wave" variant='rectangular' height={25} width="10%" />
                            </>
                        ) : (
                            data?.postTags?.map((singleTag: any) => (
                                <Typography key={uuidv4()} variant="body2" color="text.primary" className='p-2 px-4 border rounded-full'>
                                    {singleTag.name}
                                </Typography>
                            ))
                        )
                    }



                </div>

                {LOADING ? (
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        className='mx-4 w-auto'
                        sx={{ height: "330px" }}
                    />
                ) : (isPremiumContent && !isUserPremium ? (
                    <div className='h-[339px] px-4 w-full'>
                        <AppContentOverlay handleClick={() => handleUnlockContent()} />
                    </div>
                ) : (data?.postLink && data?.postType === 2) ? (
                    <CardMedia
                        muted
                        controls
                        ref={videoRef}
                        component="video"
                        src={data?.postLink}
                        className="px-4 w-full aspect-video"
                        sx={{ minHeight: '330px', objectFit: "contain" }}
                    />
                ) : (data?.postLink && data?.postType === 4) ? (
                    <Box className={`text-4xl px-4 w-full aspect-video text-white flex items-center justify-center text-center`} sx={{ background: gradient }}>
                        {data?.postText}
                    </Box>
                ) : (
                    <CardMedia
                        component="img"
                        image={data?.postLink}
                        alt="Meme"
                        className='px-4 w-full aspect-video'
                        sx={{ minHeight: '330px', objectFit: "contain" }}
                    />)
                )
                }
                <CardContent>
                    {LOADING ? (
                        <div className='flex items-center justify-between gap-4'>
                            <Skeleton animation="wave" variant="rounded" width={120} height={40} />
                            <Skeleton animation="wave" variant="rounded" width={120} height={40} />
                            <Skeleton animation="wave" variant="rounded" width={120} height={40} />
                            <Skeleton animation="wave" variant="rounded" width={120} height={40} />
                        </div>
                    ) : (

                        <div className='flex items-center justify-between gap-4'>

                            <div role='button' tabIndex={0} className='flex items-center gap-3 cursor-pointer' onClick={() => handleLike(data)}>
                                <AppLikeIcon active={data?.likeFlag === 1} />
                                <Typography variant="h6" color="text.primary" component="p" className='leading-10' style={{ color: data?.likeFlag === 1 ? '#E2B248' : '' }}>
                                    {data?.likes_count}
                                </Typography>
                            </div>

                            <div role='button' tabIndex={0} className='flex items-center gap-3 cursor-pointer' onClick={() => handleDisLike(data)}>
                                <AppDisLikeIcon active={data?.likeFlag === 2} />
                                <Typography variant="h6" color="text.primary" component="p" style={{ color: data?.likeFlag === 2 ? '#E2B248' : '' }}>
                                    {data?.dislike_count}
                                </Typography>
                            </div>

                            {/* <div role='button' tabIndex={0} className='flex items-center gap-3 cursor-pointer' onClick={() => handleComment(data)}>
                                <img src={APP_ASSETS.SVG.commentSvg} alt="comment" />
                                <Typography variant="h6" color="text.primary" component="p">
                                    {data?.comment_count}
                                </Typography>
                            </div> */}

                            <div
                                onMouseEnter={handleOpenShare}
                                onMouseLeave={handleCloseShare}
                                className="popover-container"
                            >
                                <Typography
                                    variant="h6"
                                    color="text.primary"
                                    component="p"
                                    aria-owns={open ? 'mouse-over-popover' : undefined}
                                    aria-haspopup="true"
                                    className="flex items-center gap-3 cursor-pointer"
                                >
                                    <img src={APP_ASSETS.SVG.shareSvg} alt="share" />
                                    <span>Share</span>
                                </Typography>

                                <Menu
                                    anchorEl={isOpenShare}
                                    id="account-menu"
                                    open={open}

                                    onClose={handleCloseShare}
                                    onClick={handleCloseShare}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&::before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <div className='flex items-center justify-center gap-4'>
                                        <MenuItem>
                                            <FacebookShareButton url={postShareDeepLink}>
                                                <FacebookIcon size={32} round />
                                            </FacebookShareButton>
                                        </MenuItem>

                                        <MenuItem>
                                            <WhatsappShareButton url={postShareDeepLink}>
                                                <WhatsappIcon size={32} round />
                                            </WhatsappShareButton>
                                        </MenuItem>

                                        <MenuItem>
                                            <TwitterShareButton url={postShareDeepLink}>
                                                <TwitterIcon size={32} round />
                                            </TwitterShareButton>
                                        </MenuItem>
                                    </div>

                                </Menu>
                            </div>
                        </div>
                    )}
                </CardContent>
            </Card>
        </div>
    )
}